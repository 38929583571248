export const AB_TEST_TRIGGER_TYPE_ALL_PAGE = 'all'

export const AB_TEST_CODE_ENABLE_CUSTOMILY_SAVE_PERSONALIZATION =
  'abTest_enableCustomilySavedPersonalization'
export const AB_TEST_VARIANT_ENABLE = 'enable'
export const AB_TEST_VARIANT_DISABLE = 'disable'

export const AB_TEST_CODE_SHOW_PRODUCT_SHIPPED_FROM_OPTION =
  'abTest_showProductShippedFromOption'
export const AB_TEST_VARIANT_SHOW_ALL = 'show_all'
export const AB_TEST_VARIANT_DEFAULT_OVERSEA = 'default_oversea'
export const AB_TEST_VARIANT_DEFAULT_US = 'default_us'

export const AB_TEST_SHOW_NEW_OPTION_PRODUCT_CHRISTMAS_TUMBLER =
  'abTest_showNewOptionProductChristmasTumbler'
export const AB_TEST_PRODUCT_PAGE_UI_NEW_VERSION =
  'abTest_productPageUINewVersion'
export const AB_TEST_SHOW_FLASH_SALE_COUNT_DOWN = 'abTest_flashSaleCountDown'
export const AB_TEST_PRODUCT_COMBO = 'abTest_productCombo'

export const AB_TEST_LINE_ITEM_ATTRIBUTE_KEY = '_cp-ab-test'

export const AB_TEST_CODE_SHOW_VIDEO_ON_PRODUCT_IMAGES =
  'abTest_showVideoOnProductImages'
export const AB_TEST_CODE_SHOW_VIDEO_BEHIND_THE_ATC_BUTTON =
  'show_video_behind_the_atc_button'
export const AB_TEST_VARIANT_SHOW_VIDEO_WITH_DEFAULT_CONFIG =
  'show_video_with_default_config'
export const AB_TEST_VARIANT_SHOW_VIDEO_ON_FIRST_IMAGE =
  'show_video_on_first_image'

export const AB_TEST_MEDIA_FLOW = 'abTest_mediaFlow'

export const AB_TEST_NEW_PRICE_BASE_ON_PRODUCT_TYPE =
  'abTest_newPriceBaseOnProductType'
export const AB_TEST_VARIANT_DEFAULT_PRICE = 'default_price'
export const AB_TEST_VARIANT_NEW_PRICE = 'new_price'

export const AB_TEST_SHIPPING_FEE_PROGRESS_BAR = 'abTest_shippingFeeProgressBar'
